var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('header',{staticClass:"header"}),_c('div',{staticClass:"container"},[_c('h1',{staticClass:"pagetitle",class:{'pagetitle-fallback': ['hr', 'cs'].includes(_vm.$i18n.locale)}},[_vm._v(_vm._s(_vm.$t("CustomerInformation.header")))]),_c('div',{staticClass:"box"},[_c('progressbar',{attrs:{"step":7}}),_c('ValidationObserver',{ref:"form",staticClass:"form",attrs:{"tag":"form"}},[_c('ValidationProvider',{attrs:{"rules":"partynumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Textinput',{attrs:{"help":_vm.$t('CustomerInformation.partyNumberHelp'),"label":_vm.$t('CustomerInformation.shareholderPartyNumber'),"placeholder":"FT00000000","error":errors[0]},model:{value:(_vm.shareholderPartyNumber),callback:function ($$v) {_vm.shareholderPartyNumber=$$v},expression:"shareholderPartyNumber"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"requiredInput"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
return [_c('Textinput',{attrs:{"help":_vm.$t('CustomerInformation.shareholderNameHelp'),"label":_vm.$t('CustomerInformation.shareholderName'),"error":errors[0],"required":required,"autocomplete":"name"},model:{value:(_vm.shareholderName),callback:function ($$v) {_vm.shareholderName=$$v},expression:"shareholderName"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"requiredInput|caseid"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
return [_c('Textinput',{attrs:{"help":_vm.$t('CustomerInformation.caseIdHelp'),"label":_vm.$t('CustomerInformation.caseId'),"error":errors[0],"required":required},model:{value:(_vm.caseId),callback:function ($$v) {_vm.caseId=$$v},expression:"caseId"}})]}}])}),_c('ValidationProvider',{attrs:{"rules":"requiredRadio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('CustomerInformation.isMinor'))+_vm._s('\xa0')+"* ")]),_c('div',{staticStyle:{"display":"flex"}},[_c('Radio',{staticStyle:{"margin-right":"20px"},attrs:{"label":_vm.$t('yes'),"value":"yes"},model:{value:(_vm.isMinor),callback:function ($$v) {_vm.isMinor=$$v},expression:"isMinor"}}),_c('Radio',{attrs:{"label":_vm.$t('no'),"value":"no"},model:{value:(_vm.isMinor),callback:function ($$v) {_vm.isMinor=$$v},expression:"isMinor"}})],1),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('Button',{attrs:{"loading":_vm.loading,"label":_vm.$t('CustomerInformation.button')},on:{"click":function($event){$event.preventDefault();return _vm.next.apply(null, arguments)}}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }