var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('header',{staticClass:"header"}),_c('div',{staticClass:"container"},[_c('h1',{staticClass:"pagetitle",class:{ 'pagetitle-fallback': ['hr', 'cs'].includes(_vm.$i18n.locale) }},[_vm._v(" "+_vm._s(_vm.$t("Clarification.header"))+" ")]),_c('div',{staticClass:"box"},[_c('Progressbar',{attrs:{"step":3}}),_c('h2',{staticClass:"step"},[_vm._v(_vm._s(_vm.$t("step", { stepNumber: 3 })))]),_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"form"},[_c('p',[_vm._v(_vm._s(_vm.$t("Clarification.subheader")))]),(_vm.$store.getters.answer.sourceOfFunds.includes('businessOwnership'))?_c('div',[_c('ValidationProvider',{attrs:{"rules":"requiredInput"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Textinput',{attrs:{"textarea":"","autocomplete":"off","rows":"3","help":_vm.$t('Clarification.sources.businessOwnership'),"label":_vm.$t('Clarification.q1.businessOwnership'),"error":errors[0],"required":""},model:{value:(_vm.details.businessOwnership),callback:function ($$v) {_vm.$set(_vm.details, "businessOwnership", $$v)},expression:"details.businessOwnership"}})]}}],null,false,4216464040)})],1):_vm._e(),(_vm.$store.getters.answer.sourceOfFunds.includes('familyIncome'))?_c('div',[_c('ValidationProvider',{attrs:{"rules":"requiredInput"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Textinput',{attrs:{"textarea":"","autocomplete":"off","rows":"3","help":_vm.$t('Clarification.sources.familyIncome'),"label":_vm.$t('Clarification.q1.familyIncome'),"error":errors[0],"required":""},model:{value:(_vm.details.familyIncome),callback:function ($$v) {_vm.$set(_vm.details, "familyIncome", $$v)},expression:"details.familyIncome"}})]}}],null,false,1287132920)})],1):_vm._e(),(_vm.$store.getters.answer.sourceOfFunds.includes('inheritance'))?_c('div',[_c('ValidationProvider',{attrs:{"rules":"requiredInput"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Textinput',{attrs:{"textarea":"","autocomplete":"off","rows":"3","help":_vm.$t('Clarification.sources.inheritance'),"label":_vm.$t('Clarification.q1.inheritance'),"error":errors[0],"required":""},model:{value:(_vm.details.inheritance),callback:function ($$v) {_vm.$set(_vm.details, "inheritance", $$v)},expression:"details.inheritance"}})]}}],null,false,3750514305)})],1):_vm._e(),(_vm.$store.getters.answer.sourceOfFunds.includes('saleOfProperty'))?_c('div',[_c('ValidationProvider',{attrs:{"rules":"requiredInput"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Textinput',{attrs:{"textarea":"","autocomplete":"off","rows":"3","help":_vm.$t('Clarification.sources.saleOfProperty'),"label":_vm.$t('Clarification.q1.saleOfProperty'),"error":errors[0],"required":""},model:{value:(_vm.details.saleOfProperty),callback:function ($$v) {_vm.$set(_vm.details, "saleOfProperty", $$v)},expression:"details.saleOfProperty"}})]}}],null,false,1347203032)})],1):_vm._e(),(_vm.$store.getters.answer.sourceOfFunds.includes('saleOfCompany'))?_c('div',[_c('ValidationProvider',{attrs:{"rules":"requiredInput"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Textinput',{attrs:{"textarea":"","autocomplete":"off","rows":"3","help":_vm.$t('Clarification.sources.saleOfCompany'),"label":_vm.$t('Clarification.q1.saleOfCompany'),"error":errors[0],"required":""},model:{value:(_vm.details.saleOfCompany),callback:function ($$v) {_vm.$set(_vm.details, "saleOfCompany", $$v)},expression:"details.saleOfCompany"}})]}}],null,false,2803367544)})],1):_vm._e(),(_vm.$store.getters.answer.sourceOfFunds.includes('investmentProfit'))?_c('div',[_c('ValidationProvider',{attrs:{"rules":"requiredInput"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Textinput',{attrs:{"textarea":"","autocomplete":"off","rows":"3","help":_vm.$t('Clarification.sources.investmentProfit'),"label":_vm.$t('Clarification.q1.investmentProfit'),"error":errors[0],"required":""},model:{value:(_vm.details.investmentProfit),callback:function ($$v) {_vm.$set(_vm.details, "investmentProfit", $$v)},expression:"details.investmentProfit"}})]}}],null,false,1567384090)})],1):_vm._e(),(_vm.$store.getters.answer.sourceOfFunds.includes('rentalIncome'))?_c('div',[_c('ValidationProvider',{attrs:{"rules":"requiredInput"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Textinput',{attrs:{"textarea":"","autocomplete":"off","rows":"3","help":_vm.$t('Clarification.sources.rentalIncome'),"label":_vm.$t('Clarification.q1.rentalIncome'),"error":errors[0],"required":""},model:{value:(_vm.details.rentalIncome),callback:function ($$v) {_vm.$set(_vm.details, "rentalIncome", $$v)},expression:"details.rentalIncome"}})]}}],null,false,3696805614)})],1):_vm._e(),(_vm.$store.getters.answer.sourceOfFunds.includes('other'))?_c('div',[_c('ValidationProvider',{attrs:{"rules":"requiredInput"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Textinput',{attrs:{"textarea":"","autocomplete":"off","rows":"3","help":_vm.$t('Clarification.sources.other'),"label":_vm.$t('Clarification.q1.other'),"error":errors[0],"required":""},model:{value:(_vm.details.other),callback:function ($$v) {_vm.$set(_vm.details, "other", $$v)},expression:"details.other"}})]}}],null,false,516990313)})],1):_vm._e(),(_vm.uploadVisible)?[_c('div',{staticClass:"textinput__label"},[_vm._v(_vm._s(_vm.$t('Clarification.q2.label')))]),_c('div',{staticClass:"textinput__help"},[_vm._v(_vm._s(_vm.$t('Clarification.q2.help')))]),_vm._l((_vm.files.slice(this.required, _vm.files.length)),function(file,idx){return _c('ValidationProvider',{key:idx + _vm.required,ref:"file",refInFor:true,attrs:{"rules":"mimes:image/png,image/jpeg,application/pdf","name":("Doc-" + idx + "-file")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('Fileinput',{staticClass:"small-margin",attrs:{"accept":"image/png, image/jpeg, application/pdf","capture":"","error":errors[0]},model:{value:(_vm.files[idx + _vm.required]),callback:function ($$v) {_vm.$set(_vm.files, idx + _vm.required, $$v)},expression:"files[idx + required]"}})]}}],null,true)})}),_c('div',[_c('a',{staticClass:"add",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.files.push(null)}}},[_vm._v(_vm._s(_vm.$t('Clarification.addDocument')))]),_c('br')])]:_vm._e(),_c('Button',{attrs:{"loading":_vm.loading,"label":_vm.$t('ProofOfAddress.button')},on:{"click":_vm.next}})],2)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }